import React, {Component} from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios'
import config from '../config'
const TP_API_URL = config.TP_API_URL;

class ShopSelect extends Component {

   
    constructor(props) {
        super(props);
        this.state = {
          items: [
              {name:'All shops', id:0}
            ]
        };
    }
    

    handleInputChange = event => {
        this.props.onCallback(event.target.value);
        
    }

    componentDidMount() {
        axios.get(TP_API_URL+'admin/shops').then((res) => {
            const response = res.data;
            this.setState({
                items:response.items
            });
        });
    };


    render() {
        let optionTemplate = this.state.items.map(v => (
            <option value={v.id} key={v.id}>{v.name}</option>
        ));

        return (
          <div>
              <Form.Group>
                <Form.Control size="sm" as="select" custom value={this.props.shop} onChange={this.handleInputChange}>
                    {optionTemplate}
                </Form.Control>
            </Form.Group>
          </div>
        );
    }
}

export default ShopSelect;