import React, {Component} from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import axios from 'axios'
import config from '../../config'
const TP_API_URL = config.TP_API_URL;

const datasetKeyProvider=()=>{ 
  return btoa(Math.random()).substring(0,12)
} 

const options = {
  plugins:{
    datalabels: {
      color: '#333',
      display: 'auto',
      align: 'end',
      anchor: 'end',
      font: {
        weight: 'bold'
      },
      formatter: function(value, context) {
        return value.toFixed(0);
      }
    }
  },
  legend: {
      display: false
  },
  tooltips: {
      custom: function(tooltip) {
        if (!tooltip) return;
        tooltip.displayColors = false;
      },
      callbacks: {
          label: function(tooltipItem, data) {
              return tooltipItem.xLabel + " : "+tooltipItem.yLabel.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          },
          title: function(tooltipItem, data) {
            return;
          }
      }
  },
  scales: {
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: 'Number of orders recovered'
      },
      afterDataLimits(scale) {
        var range = scale.max-scale.min;
        var grace = range * 0.1; 
        scale.max += grace ;
        scale.min -= grace;
      }
    }]
  }
}

let source = axios.CancelToken.source();
class Quantityp extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
      data: {},
      loading:false
    };

    getchartdata = async () => {
      if (source) { source.cancel();}
      source = axios.CancelToken.source();
      
      this.setState({data:{},loading:true});
      var start = this.props.start;
      var end = this.props.end;
      var shop = this.props.shop;
      axios.get(TP_API_URL+'admin/charts/quantity-purchase', {params:{start:start, end:end, shop:shop}, cancelToken: source.token}).then((res) => {
        const response = res.data;
        this.setState({
          data: {
            labels: response.xaxis,
            datasets: [
              {
                label: "",
                fill: true,
                lineTension: 0.4,
                backgroundColor: 'rgba(149,236,173,0.5)',
                borderColor: 'rgba(51,204,51,1)',
                borderCapStyle: 'butt',
                borderDash: [5],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(51,204,51,1)',
                pointBackgroundColor: '#ffffff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(51,204,51,1)',
                pointHoverBorderColor: 'rgba(51,204,51,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,
                data: response.yaxis
              }
            ]
          },
          loading: false
        });
      })
      .catch((e) => {
        //console.log(e.message);
      });
    }

    componentDidMount() {
        this.getchartdata();
    }

    componentDidUpdate(prevProps) {
      if (this.props.start !== prevProps.start || this.props.end !== prevProps.end || this.props.shop !== prevProps.shop) {
        this.getchartdata();
      }
    }

    componentWillUnmount() {
      if (source) { source.cancel();}
    }

    render() {
      return (
        <div className="chartwrapper">
          {this.state.loading ? <div className="loading">Loading...</div> : null}
          <Line ref="chart" data={this.state.data} options={options} height={100} className="areaChart" datasetKeyProvider={datasetKeyProvider} />
        </div>
      );
    }
}


export default Quantityp;