import React, {Component} from 'react';
import axios from 'axios';
import config from '../../config'
import { Funnel } from 'react-kvn-funnel';
const TP_API_URL = config.TP_API_URL;

let source = axios.CancelToken.source();
class CFunnel extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data: [{
            "label": "Initiated conversations",
            "percent": 0,
            "quantity": 100
        },
        {
            "label": "Replied",
            "percent": 0,
            "quantity": 75
        },
        {
            "label": "Purchased",
            "percent": 0,
            "quantity": 25
        }],
        loading:false
    };
  
    getchartdata = async () => {
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}],loading:true});
        var start = this.props.start;
        var end = this.props.end;
        var shop = this.props.shop;
        axios.get(TP_API_URL+'admin/charts/funnel', {params:{start:start, end:end, shop:shop}, cancelToken: source.token}).then((res) => {
          const response = res.data;
          
          this.setState({
            data:[
                {
                    "label": "Initiated conversations",
                    "percent": 0,
                    "quantity": response.total
                },
                {
                    "label": "Replied",
                    "percent": 0,
                    "quantity": response.replied
                },
                {
                    "label": "Purchased",
                    "percent": 0,
                    "quantity": response.recoverd
                }
            ],
            loading: false
          });
        })
        .catch((e) => {
            //console.log(e.message);
        });
    }

    componentDidMount() {
        this.getchartdata();
    }

    componentDidUpdate(prevProps) {
      if (this.props.start !== prevProps.start || this.props.end !== prevProps.end || this.props.shop !== prevProps.shop) {
        this.getchartdata();
      }
    }

    componentWillUnmount() {
        if (source) { source.cancel();}
    }

    render() {
        return (
          <div className="chartwrapper">
            {this.state.loading ? <div className="loading">Loading...</div> : 
                <Funnel 
                  height={150}
                  width={300}
                  colors={{
                    graph: [ '#E2F1FA', '#86D0FC' ],
                  }}
                  labelKey='label'
                  valueKey='quantity'
                  displayLabel={true}
                  responsive={true}
                  data={this.state.data}
                  displayPercent={true}
                />
            }
          </div>
        );
    }

}


export default CFunnel;