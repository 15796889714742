import React from "react";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH, faChartPie, faStore, faSms, faUserTie, faCog, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/logo.svg'

function hidenavbar(){
    document.body.classList.add('hidenav');
}

function Navbar() {
    return (
        <section id="sidebar">
            <div className="navbar-wrapper">
                <div className="brand">
                    <img src={Logo} alt="Cart Rescue" />
                </div>

                <div className="navbar">
                    <ul>
                        <li>
                            <NavLink exact to="/" onClick={hidenavbar}><FontAwesomeIcon icon={faSlidersH} className="menuicon mr-8" /> Dashboard</NavLink>
                        </li>
                        <li>
                            <NavLink to="/insight" onClick={hidenavbar} ><FontAwesomeIcon icon={faChartPie} className="menuicon mr-8" /> Insight</NavLink>
                        </li>
                        <li>
                            <NavLink to="/shops" onClick={hidenavbar}><FontAwesomeIcon icon={faStore} className="menuicon mr-8" /> Shops</NavLink>
                        </li>
                        <li>
                            <NavLink to="/conversations/0/1" onClick={hidenavbar}><FontAwesomeIcon icon={faSms} className="menuicon mr-8" /> Conversations</NavLink>
                        </li>
                        <li>
                            <NavLink to="/agents" onClick={hidenavbar}><FontAwesomeIcon icon={faUserTie} className="menuicon mr-8" /> Agents</NavLink>
                        </li>
                        <li className="divider"></li>
                        <li>
                            <NavLink to="/settings" onClick={hidenavbar}><FontAwesomeIcon icon={faCog} className="menuicon mr-8" /> Settings</NavLink>
                        </li>
                        <li>
                            <NavLink to="/logout" onClick={hidenavbar}><FontAwesomeIcon icon={faPowerOff} className="menuicon mr-8" /> Logout</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Navbar;