import React, { Component} from 'react';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShoppingCart, faPercentage, faPlus, faTrash, faArrowUp, faArrowDown, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import ShopSelect from "../components/Shopselect";
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Abandoned from "../components/charts/Abandoned";
import config from '../config'
import { Funnel } from 'react-kvn-funnel';
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");

let source = axios.CancelToken.source();

class Dashboard extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data:{},
        loading:false,
        shop:0,
        start: moment().subtract(9, 'days'),
        end: moment(),
        data_abd: {
            total1: 0,
            total2: 0,
            shop_plan: 0,
            shop_per: 0
        }
    };

    handleCallback = (start, end) => {
        this.setState({
            start: start,
            end: end
        });
    };

    setShop = (shop) => {
        this.setState({
            shop: shop
        });
    }

    dataCust = (v1, v2, v3, v4) => {
        this.setState({
            data_abd: {
                total1: v1,
                total2: v2,
                shop_plan: v3,
                shop_per: v4
            }
        });

    }

    getdata = () => {
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:{}, loading:true});
        axios.get(TP_API_URL+'admin/dashboard', {
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          this.setState({
            data: response.data,
            loading:false
          });
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentDidMount(){
        this.getdata();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    shownavbar = () =>{
        document.body.classList.remove('hidenav');
    }

    render () {

        const funnel_data = [
            {
                "label": "Initiated conversations",
                "percent": 0,
                "quantity": this.state.data.total
            },
            {
                "label": "Replied",
                "percent": 0,
                "quantity": this.state.data.replied
            },
            {
                "label": "Purchased",
                "percent": 0,
                "quantity": this.state.data.recoverd
            }
        ]

        const label = this.state.start.format('MMMM D, YYYY') + ' - ' + this.state.end.format('MMMM D, YYYY');

        const CDtoday = {
            v1:0,
            v2:0,
            c1:"0",
            c2:"text-success",
            c3:"faArrowUp"
        };

        const CDcust = {
            v1:0,
            v2:0,
            c1:"0",
            c2:"text-success",
            c3:"faArrowUp"
        };

        return (
            <>
                <Container fluid={true}>
                    <Row>
                        <Col>
                            <h3 className="page-heading">
                                <Button variant="light" onClick={this.shownavbar} size="sm" className="menu mr-5">
                                    <FontAwesomeIcon icon={faBars} className="menuicon" />
                                </Button> 
                                Dashboard
                            </h3>
                        </Col>
                    </Row>
                </Container>
                {this.state.loading ? <div className="loading">Loading...</div> :
                <>
                <Container fluid >
                    <Row className="gx-2">
                        <Col className='mb-20'>
                            <Card body className="statistics s1">
                                <Row>
                                    <Col xl={3} className="sicon_wrapper">
                                        <FontAwesomeIcon icon={faShoppingCart} className="sicon" />
                                    </Col>
                                    <Col xl={9}>
                                        <h5>${this.state.data.amount_recovered}</h5>
                                        <p>Carts Recovered</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className='mb-20'>
                            <Card body className="statistics s2">
                                <Row>
                                    <Col xl={3} className="sicon_wrapper" >
                                        <FontAwesomeIcon icon={faPercentage} className="sicon" />
                                    </Col>
                                    <Col xl={9}>
                                        <h5>${this.state.data.commission}</h5>
                                        <p>Commissions</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className='mb-20'>
                            <Card body className="statistics s3">
                                <Row>
                                    <Col xl={3} className="sicon_wrapper">
                                        <FontAwesomeIcon icon={faPlus} className="sicon" />
                                    </Col>
                                    <Col xl={9}>
                                        <h5>{this.state.data.shop_installed}</h5>
                                        <p>Store Installed</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className='mb-20'>
                            <Card body className="statistics s4">
                                <Row>
                                    <Col xl={3} className="sicon_wrapper">
                                        <FontAwesomeIcon icon={faTrash} className="sicon" />
                                    </Col>
                                    <Col xl={9}>
                                        <h5>{this.state.data.shop_uninstalled}</h5>
                                        <p>Store Uninstalled</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="mb-20">
                    <Row>
                        <Col sm={12}>
                            <h4 className="page-heading">Conversion Funnel</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card body>
                                <div className="chartwrapper">
                                <Funnel 
                                    height={150}
                                    width={300}
                                    colors={{
                                        graph: [ '#E2F1FA', '#86D0FC' ],
                                    }}
                                    labelKey='label'
                                    valueKey='quantity'
                                    displayLabel={true}
                                    responsive={true}
                                    data={funnel_data}
                                    displayPercent={true}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className="mb-20">
                    <Row>
                        <Col sm={6}>
                            <h4 className="page-heading">
                                Commissions
                            </h4>
                        </Col>
                        <Col sm={3}>
                            <div className="mt-10"><ShopSelect shop={this.state.shop} onCallback={this.setShop}></ShopSelect></div>
                        </Col>
                        <Col sm={3}>
                            <DateRangePicker
                                initialSettings={{
                                startDate: this.state.start.toDate(),
                                endDate: this.state.end.toDate(),
                                ranges: {
                                    'Last 7 Days': [
                                    moment().subtract(6, 'days').toDate(),
                                    moment().toDate(),
                                    ],
                                    'Last 10 Days': [
                                        moment().subtract(9, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'Last 30 Days': [
                                    moment().subtract(29, 'days').toDate(),
                                    moment().toDate(),
                                    ],
                                    'This Month': [
                                    moment().startOf('month').toDate(),
                                    moment().endOf('month').toDate(),
                                    ],
                                    'Last Month': [
                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                    ],
                                },
                                }}
                                onCallback={this.handleCallback}
                            >
                                <div
                                id="reportrange"
                                className="form-control-sm form-control mt-10"
                                style={{
                                    background: '#fff',
                                    cursor: 'pointer',
                                    padding: '5px 10px',
                                    border: '1px solid #ccc',
                                    width: '100%',
                                }}
                                >
                                <i className="fa fa-calendar"></i>&nbsp;
                                <span>{label}</span> <i className="fa fa-caret-down"></i>
                                <input type="hidden" id="startdate" value={this.state.start.format('YYYY-MM-DD')} />
                                <input type="hidden" id="enddate" value={this.state.end.format('YYYY-MM-DD')} />
                                </div>
                            </DateRangePicker>
                        </Col>
                    </Row>

                    <Row className="gx-2">
                        <Col className='mb-20'>
                            <Card body className="statistics s2">
                                <Row>
                                    <Col xl={3} className="sicon_wrapper">
                                        <FontAwesomeIcon icon={faShoppingCart} className="sicon" />
                                    </Col>
                                    <Col xl={9}>
                                        <h5>${this.state.data_abd.total1}</h5>
                                        <p>Total amount recovered</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className='mb-20'>
                            <Card body className="statistics s3">
                                <Row>
                                    <Col xl={3} className="sicon_wrapper" >
                                        <FontAwesomeIcon icon={faPercentage} className="sicon" />
                                    </Col>
                                    <Col xl={9}>
                                        <h5>${this.state.data_abd.total2}</h5>
                                        <p>Commissions</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        {this.state.data_abd.shop_plan>0 ?
                        <Col className='mb-20'>
                            <Card body className="statistics s1">
                                <Row>
                                    <Col xl={3} className="sicon_wrapper">
                                        <FontAwesomeIcon icon={faCreditCard} className="sicon" />
                                    </Col>
                                    <Col xl={9}>
                                        <h5>{this.state.data_abd.shop_per}%</h5>
                                        <p>{this.state.data_abd.shop_plan===1 ? "STARTER (Free)" : ""} {this.state.data_abd.shop_plan===2 ? "GROWTH ($49/month)" : ""} {this.state.data_abd.shop_plan===3 ? "PRO ($299/month)" : ""} {this.state.data_abd.shop_plan===4 ? "ENTERPRISE ($999/month)" : ""}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        : "" }
                    </Row>

                    {/*
                    <Tab.Container id="chart-tab" defaultActiveKey="first" >
                        <Row noGutters className="chart-tab">
                            <Col sm={4}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">
                                        <Row noGutters>
                                            <Col sm={7}>
                                                <p className="m-0">Selected date range</p>
                                                <h5 className="fw-bold">${CDcust.v1} 
                                                    {(CDcust.c1>0) ?
                                                        <small className={CDcust.c2}>
                                                            {(CDcust.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {CDcust.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h5>
                                            </Col>
                                            <Col sm={5} className="text-right text-muted">
                                                <p className="m-0">Previous</p>
                                                <h5>${CDcust.v2}</h5>
                                            </Col>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <Row noGutters>
                                            <Col sm={7}>
                                                <p className="m-0">Today</p>
                                                <h5 className="fw-bold">${CDtoday.v1} 
                                                    {(CDtoday.c1>0) ?
                                                        <small className={CDtoday.c2}>
                                                            {(CDtoday.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {CDtoday.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h5>
                                            </Col>
                                            <Col sm={5} className="text-right text-muted">
                                                <p className="m-0">Yesterday</p>
                                                <h5>${CDtoday.v2}</h5>
                                            </Col>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            </Col>
                            <Col sm={8}>
                            
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Container className="mb-15">
                                        <Row>
                                            <Col sm={6}>
                                                <p className="m-0">Selected date range</p>
                                                <h3 className="fw-bold">${CDcust.v1} 
                                                    {(CDcust.c1>0) ?
                                                        <small className={CDcust.c2}>
                                                            {(CDcust.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {CDcust.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h3>
                                            </Col>
                                            <Col sm={6} className="text-right text-muted">
                                                <p className="m-0">Previous</p>
                                                <h3>${CDcust.v2}</h3>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Abandoned start={this.state.start.format('YYYY-MM-DD')} end={this.state.end.format('YYYY-MM-DD')} shop={this.state.shop} onCallback={this.dataCust} ></Abandoned>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Container className="mb-15">
                                        <Row>
                                            <Col sm={6}>
                                                <p className="m-0">Today</p>
                                                <h3 className="fw-bold">${CDtoday.v1} 
                                                    {(CDtoday.c1>0) ?
                                                        <small className={CDtoday.c2}>
                                                            {(CDtoday.c3==='faArrowUp') ? 
                                                            <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                            <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                            {CDtoday.c1}%
                                                        </small>
                                                        : ""
                                                    }
                                                </h3>
                                            </Col>
                                            <Col sm={6} className="text-right text-muted">
                                                <p className="m-0">Yesterday</p>
                                                <h3>${CDtoday.v2}</h3>
                                            </Col>
                                        </Row>
                                    </Container>

                                    <Custom start={this.state.start.format('YYYY-MM-DD')} end={this.state.end.format('YYYY-MM-DD')} shop={this.state.shop} onCallback={this.dataCust} ></Custom>
                                </Tab.Pane>
                            </Tab.Content>
                            

                            </Col>
                        </Row>
                    </Tab.Container>
                    */}

                    
                    <Row>
                        <Col>
                            <Abandoned start={this.state.start.format('YYYY-MM-DD')} end={this.state.end.format('YYYY-MM-DD')} shop={this.state.shop} onCallback={this.dataCust} ></Abandoned>
                        </Col>
                    </Row>
                    
                </Container>
                </>
                }
            </>
        );
    }
}

export default Dashboard;