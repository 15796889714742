import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Container, Col, Row, Card, Table, Button, Modal, Form, InputGroup, Alert } from 'react-bootstrap';
import Logo from '../assets/logo.svg'
import config from '../config'
const TP_API_URL = config.TP_API_URL;

function Login({setToken}) {

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();
  
    const handleSubmit = (event) => {
        event.preventDefault();
        setError("");
        axios.post(TP_API_URL+'admin/login', {
            username:username,
            password:password
        })
        .then((res) => {
            const response = res.data;
            if(response.error===0){
                const token = response.token;
                setToken(token);
            } else {
                setError(response.errortext);
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    return(
        <>
            <Container fluid>
                <Row>
                    <Col className="loginbox-wrapper">
                        <div style={{ width: '24rem' }} >
                            <div className="mb-20 text-center">
                                <img src={Logo} alt="Cart Rescue" />
                            </div>
                            <Card border="primary">
                                <Card.Header as="h5" className="bg-primary text-light" >Login</Card.Header>
                                <Card.Body>
                                    {error ? <Alert variant="danger">{error}</Alert> : ""}
                                    <form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-15" controlId="username">
                                            <Form.Label >Username</Form.Label>
                                            <Form.Control type="text" name="username" onChange={e => setUserName(e.target.value)} required="true" />
                                        </Form.Group>

                                        <Form.Group className="mb-15" controlId="password">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" name="password" onChange={e => setPassword(e.target.value)} required="true" />
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <Button type="submit" variant="primary" style={{ width: '100%' }}>Login</Button>
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login;