import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {initials} from "../helper/General";
import config from '../config'
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");
var now = new Date();

let source = axios.CancelToken.source();

class ConversationsView extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data:[{}],
        loading:false,
        abd:[{}]
    };

    

    getdata = () => {
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}], loading:true});
        var id = this.props.match.params.id;
        axios.get(TP_API_URL+'admin/conversationView', {params:{id:id},
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          const data = response.data;
          this.setState(
                {
                    data:data, 
                    loading:false,
                    abd:response.abdata
                }
            );
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentDidMount(){
        this.getdata();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    GoBack = () => {
        this.props.history.goBack();
    }

    CreateChat = () => {
        let cdata = [];
        for (var i = 0; i < this.state.data.length; i++) {
            var fdata = this.state.data[i];
            var self = "self";
            var name = "Agent";
            var bg = "bg-info";
            if(fdata.sms_received=='1'){
                self="";
                name=this.state.abd.cart_name;
                bg = "bg-dark";
            }
            cdata.push(
                <div className={"message " + self}>
                    <div className="message-wrapper">
                        <div className="message-content">
                            <span>{fdata.sms_body}</span>
                        </div>
                    </div>
                    <div className="message-options">
                        <div className={"avatar avatar-sm text-light " + bg}>
                            <span>{initials(name)}</span>
                        </div>
                        <span class="message-date">{dateFormat(fdata.created_on)}</span>
                    </div>
				</div>
            );
        }
        return cdata;
    }

    render() {
        
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> : 
                <Container fluid>
                    <Row>
                        <Col sm={8}>
                            <Card className="vh100">
                                <Card.Header as="h5">
                                    <Button variant="light" onClick={this.GoBack} size="sm">
                                        <FontAwesomeIcon icon={faArrowLeft} className="menuicon mr-5" /> Go Back
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <div className="chat-body">
                                        <div className="chat-content p-2" id="messageBody">
                                            {this.CreateChat()}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={4}>
                            <Card className="vh100">
                                <Card.Header as="h5">
                                    Cart Details
                                </Card.Header>
                                <Card.Body>
                                    <h6>User Information</h6>
                                    <p><small className="text-muted">Name:</small><br />{this.state.abd.cart_name}</p>
                                    <p><small className="text-muted">Phone:</small><br />{this.state.abd.cart_phone}</p>
                                    <p><small className="text-muted">Email:</small><br />{this.state.abd.cart_email}</p>
                                    <p><small className="text-muted">Country:</small><br />{this.state.abd.cart_country}</p>
                                    <p><small className="text-muted">ZIP:</small><br />{this.state.abd.cart_zip}</p>
                                    <h6>Cart Information</h6>
                                    <p><small className="text-muted">Order total:</small><br />${this.state.abd.order_total}</p>
                                    <p><small className="text-muted">Discount Code:</small><br />{this.state.abd.cart_discount_code}</p>
                                    <p><small className="text-muted">Link with discount:</small><br />{this.state.abd.short_url_discount}</p>
                                    <p><small className="text-muted">Link w/o discount:</small><br />{this.state.abd.short_url_no_discount}</p>
                                    <p><small className="text-muted">Is recovered?:</small><br />{this.state.abd.order_recovered=='1' ? "Yes" : "No"}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }
            </>
        
        )
    }
}

export default withRouter(ConversationsView);