import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './assets/pd-mr.css';
import './assets/App.css';


import Navbar from './components/Navbar'

/* Pages */

import Dashboard from './pages/Dashboard'
import Insight from './pages/Insight'
import Shops from './pages/Shops'
import ShopsView from './pages/ShopsView'
import Conversations from './pages/Conversations'
import ConversationsView from './pages/ConversationsView';
import Agents from './pages/Agents'
import AgentView from './pages/AgentView'
import Settings from './pages/Settings'
import Login from './pages/Login';
import Logout from './pages/Logout'

import Token from './helper/Token'

function hidenavbar(){
  document.body.classList.add('hidenav');
}

function App() {
  const { token, setToken } = Token();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div id="wrapper">
        <BrowserRouter>
            <div className="mobile-overlay" onClick={hidenavbar}></div>
            <Navbar />
            <section id="content">
                <Switch>
                    <Route exact path="/"> <Dashboard /> </Route>
                    <Route path="/insight"> <Insight /> </Route>
                    <Route path="/shops"> <Shops /> </Route>
                    <Route path="/shop/view/:id"> <ShopsView /> </Route>
                    <Route path="/conversations/:shop/:page"> <Conversations /> </Route>
                    <Route path="/conversation/view/:id"> <ConversationsView /> </Route>
                    <Route path="/agents"> <Agents /> </Route>
                    <Route path="/agent/view/:id"> <AgentView /> </Route>
                    <Route path="/settings"> <Settings /> </Route>
                    <Route path="/logout"><Logout /> </Route>
                </Switch>
            </section>
        </BrowserRouter>
    </div>

  );
}

export default App;