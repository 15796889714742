import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Tab, Nav, Badge, Button } from 'react-bootstrap';
import ShopSelect from "../components/Shopselect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import config from '../config'
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");

const columns = [
    {
      name: 'Shop',
      selector: 'shop_name',
      sortable: false
    },

    {
        name: 'Name',
        selector: 'sms_to',
        sortable: false,
        right: false,
        cell: row => <div data-tag="allowRowEvents"><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.cart_name}</div>{row.sms_to}</div>,
    },
    {
        name: 'Last Contacted',
        selector: 'updated_on',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.updated_on ? dateFormat(row.updated_on) : ""}</div>,
    },
    {
        name: 'Total Messages',
        selector: 'sms_total',
        sortable: false,
        right: false
    },
    {
        name: 'Amount',
        selector: 'order_total',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.order_total ? "$"+row.order_total : ""}</div>,
    },
    {
        name: 'Status',
        selector: 'cart_status',
        sortable: false,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.cart_status}</div>,
    }
];

let source = axios.CancelToken.source();
class Conversations extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }




    state = {
        data:[{}],
        total:0,
        loading:false,
        shop:0,
        page:1,
    };

    setShop = (id) => {
        this.setState({shop:id});
        this.setState({page:"1"});
    }

    setPage = (page) => {
        this.setState({page:page});
    }

    getdata = (id) => {
        this.props.history.push("/conversations/"+this.state.shop+"/"+this.state.page);
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}], loading:true});
        axios.get(TP_API_URL+'admin/conversationslist', {
            params:{
                shopid: this.state.shop,
                page:this.state.page
            },
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          this.setState({data:response.data, total:response.total, loading:false});
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    componentDidMount(){
        var ushop = this.props.match.params.shop;
        var upage = this.props.match.params.page;
        if(!ushop){ ushop = 0;}
        if(!upage){ upage=1;}
        this.setState({shop:ushop});
        this.setState({page:upage});
        this.getdata();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!=this.state.page || prevState.shop!=this.state.shop){
            this.getdata();
        }
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    routeChange=(data)=> {
        this.props.history.push("/conversation/view/"+data.id);
    }

    shownavbar = () =>{
        document.body.classList.remove('hidenav');
    }
    
    render () {
        return(
            <>
                <Container fluid>
                    <Row>
                        <Col sm={9}>
                            <h3 className="page-heading">
                                <Button variant="light" onClick={this.shownavbar} size="sm" className="menu mr-5">
                                    <FontAwesomeIcon icon={faBars} className="menuicon" />
                                </Button> 
                                Conversations
                            </h3>
                        </Col>
                        <Col sm={3}>
                            <div className="mt-10"><ShopSelect shop={this.state.shop} onCallback={this.setShop}></ShopSelect></div>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col>
                            <Card body>
                                {this.state.loading ? <div className="loading">Loading...</div> : null }
                                <DataTable
                                    title="Conversations"
                                    columns={columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    onRowClicked={this.routeChange}
                                    pagination={true}
                                    paginationServer={true}
                                    page={this.state.page}
                                    paginationTotalRows={this.state.total}
                                    paginationPerPage={100}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true
                                    }}
                                    onChangePage={page => this.setPage(page)}
                                    paginationDefaultPage={this.state.page}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withRouter(Conversations);