import React, {useState} from "react";
import { Container, Col, Row, Card, Tab, Nav, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faBars } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';


import AvgOrderValue from '../components/charts/Avgordervalue';
import Conversations from "../components/charts/Conversations";
import Quantityp from "../components/charts/Quantityp";
import Recovery from "../components/charts/Recovery";
import ShopSelect from "../components/Shopselect";
import Today from "../components/charts/Today";
import Week from "../components/charts/Week";
import Month from "../components/charts/Month";
import Year from "../components/charts/Year";
import Custom from "../components/charts/Custom";
import Funnel from "../components/charts/Funnel";
import config from '../config'
const TP_API_URL = config.TP_API_URL;

function Insight() {

    const [state, setState] = useState({
        start: moment().subtract(9, 'days'),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
    };

    const [shop, setShop] = useState(0);

    const label = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');


    const [CDtoday, setCDtoday] = useState({
        v1:0,
        v2:0,
        c1:"0",
        c2:"text-success",
        c3:"faArrowUp"
    });

    function dataToday(v1, v2, c1, c2, c3) {
        setCDtoday({
            v1:v1,
            v2:v2,
            c1:c1,
            c2:c2,
            c3:c3
        });
    }


    const [CDweek, setCDweek] = useState({
        v1:0,
        v2:0,
        c1:"0",
        c2:"text-success",
        c3:"faArrowUp"
    });


    function dataWeek(v1, v2, c1, c2, c3) {
        setCDweek({
            v1:v1,
            v2:v2,
            c1:c1,
            c2:c2,
            c3:c3
        });
    }



    const [CDmonth, setCDmonth] = useState({
        v1:0,
        v2:0,
        c1:"0",
        c2:"text-success",
        c3:"faArrowUp"
    });


    function dataMonth(v1, v2, c1, c2, c3) {
        setCDmonth({
            v1:v1,
            v2:v2,
            c1:c1,
            c2:c2,
            c3:c3
        });
    }


    const [CDyear, setCDyear] = useState({
        v1:0,
        v2:0,
        c1:"0",
        c2:"text-success",
        c3:"faArrowUp"
    });


    function dataYear(v1, v2, c1, c2, c3) {
        setCDyear({
            v1:v1,
            v2:v2,
            c1:c1,
            c2:c2,
            c3:c3
        });
    }


    const [CDcust, setCDcust] = useState({
        v1:0,
        v2:0,
        c1:"0",
        c2:"text-success",
        c3:"faArrowUp"
    });


    function dataCust(v1, v2, c1, c2, c3) {
        setCDcust({
            v1:v1,
            v2:v2,
            c1:c1,
            c2:c2,
            c3:c3
        });
    }

    const shownavbar = () =>{
        document.body.classList.remove('hidenav');
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm={6}>
                        <h3 className="page-heading">
                            <Button variant="light" onClick={shownavbar} size="sm" className="menu mr-5">
                                 <FontAwesomeIcon icon={faBars} className="menuicon" />
                            </Button> 
                            Insight
                        </h3>
                    </Col>
                    <Col sm={3}>
                        <div className="mt-10"><ShopSelect shop={shop} onCallback={setShop}></ShopSelect></div>
                    </Col>
                    <Col sm={3}>
                        <DateRangePicker
                            initialSettings={{
                            startDate: start.toDate(),
                            endDate: end.toDate(),
                            ranges: {
                                'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                                ],
                                'Last 10 Days': [
                                    moment().subtract(9, 'days').toDate(),
                                    moment().toDate(),
                                ],
                                'Last 30 Days': [
                                moment().subtract(29, 'days').toDate(),
                                moment().toDate(),
                                ],
                                'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                                ],
                                'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                                ],
                            },
                            }}
                            onCallback={handleCallback}
                        >
                            <div
                            id="reportrange"
                            className="form-control-sm form-control mt-10"
                            style={{
                                background: '#fff',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                border: '1px solid #ccc',
                                width: '100%',
                            }}
                            >
                            <i className="fa fa-calendar"></i>&nbsp;
                            <span>{label}</span> <i className="fa fa-caret-down"></i>
                            <input type="hidden" id="startdate" value={state.start.format('YYYY-MM-DD')} />
                            <input type="hidden" id="enddate" value={state.end.format('YYYY-MM-DD')} />
                            </div>
                        </DateRangePicker>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <h4 className="page-heading">Sales <br /> <small>Overview of recovered carts</small></h4>
                    </Col>
                </Row>
                <Tab.Container id="chart-tab" defaultActiveKey="first" >
                    <Row noGutters className="chart-tab">
                        <Col sm={4}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    <Row noGutters>
                                        <Col sm={7}>
                                            <p className="m-0">Selected date range</p>
                                            <h5 className="fw-bold">${CDcust.v1} 
                                                {(CDcust.c1>0) ?
                                                    <small className={CDcust.c2}>
                                                        {(CDcust.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDcust.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h5>
                                        </Col>
                                        <Col sm={5} className="text-right text-muted">
                                            <p className="m-0">Previous</p>
                                            <h5>${CDcust.v2}</h5>
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">
                                    <Row noGutters>
                                        <Col sm={7}>
                                            <p className="m-0">Today</p>
                                            <h5 className="fw-bold">${CDtoday.v1} 
                                                {(CDtoday.c1>0) ?
                                                    <small className={CDtoday.c2}>
                                                        {(CDtoday.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDtoday.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h5>
                                        </Col>
                                        <Col sm={5} className="text-right text-muted">
                                            <p className="m-0">Yesterday</p>
                                            <h5>${CDtoday.v2}</h5>
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">
                                    <Row noGutters>
                                        <Col sm={7}>
                                            <p className="m-0">This week</p>
                                            <h5 className="fw-bold">${CDweek.v1} 
                                                {(CDweek.c1>0) ?
                                                    <small className={CDweek.c2}>
                                                        {(CDweek.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDweek.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h5>
                                        </Col>
                                        <Col sm={5} className="text-right text-muted">
                                            <p className="m-0">Last week</p>
                                            <h5>${CDweek.v2}</h5>
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fourth">
                                    <Row noGutters>
                                        <Col sm={7}>
                                            <p className="m-0">This month</p>
                                            <h5 className="fw-bold">${CDmonth.v1} 
                                                {(CDmonth.c1>0) ?
                                                    <small className={CDmonth.c2}>
                                                        {(CDmonth.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDmonth.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h5>
                                        </Col>
                                        <Col sm={5} className="text-right text-muted">
                                            <p className="m-0">Last month</p>
                                            <h5>${CDmonth.v2}</h5>
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth">
                                    <Row noGutters>
                                        <Col sm={7}>
                                            <p className="m-0">This year</p>
                                            <h5 className="fw-bold">${CDyear.v1} 
                                                {(CDyear.c1>0) ?
                                                    <small className={CDyear.c2}>
                                                        {(CDyear.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDyear.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h5>
                                        </Col>
                                        <Col sm={5} className="text-right text-muted">
                                            <p className="m-0">Last year</p>
                                            <h5>${CDyear.v2}</h5>
                                        </Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </Col>
                        <Col sm={8}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Container className="mb-15">
                                    <Row>
                                        <Col sm={6}>
                                            <p className="m-0">Selected date range</p>
                                            <h3 className="fw-bold">${CDcust.v1} 
                                                {(CDcust.c1>0) ?
                                                    <small className={CDcust.c2}>
                                                        {(CDcust.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDcust.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h3>
                                        </Col>
                                        <Col sm={6} className="text-right text-muted">
                                            <p className="m-0">Previous</p>
                                            <h3>${CDcust.v2}</h3>
                                        </Col>
                                    </Row>
                                </Container>
                                <Custom start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop} onCallback={dataCust} ></Custom>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Container className="mb-15">
                                    <Row>
                                        <Col sm={6}>
                                            <p className="m-0">Today</p>
                                            <h3 className="fw-bold">${CDtoday.v1} 
                                                {(CDtoday.c1>0) ?
                                                    <small className={CDtoday.c2}>
                                                        {(CDtoday.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDtoday.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h3>
                                        </Col>
                                        <Col sm={6} className="text-right text-muted">
                                            <p className="m-0">Yesterday</p>
                                            <h3>${CDtoday.v2}</h3>
                                        </Col>
                                    </Row>
                                </Container>

                                <Today start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop} onCallback={dataToday}></Today>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <Container className="mb-15">
                                    <Row>
                                        <Col sm={6}>
                                            <p className="m-0">This week</p>
                                            <h3 className="fw-bold">${CDweek.v1} 
                                                {(CDweek.c1>0) ?
                                                    <small className={CDweek.c2}>
                                                        {(CDweek.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDweek.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h3>
                                        </Col>
                                        <Col sm={6} className="text-right text-muted">
                                            <p className="m-0">Last week</p>
                                            <h3>${CDweek.v2}</h3>
                                        </Col>
                                    </Row>
                                </Container>
                                <Week start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop} onCallback={dataWeek}></Week>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <Container className="mb-15">
                                    <Row>
                                        <Col sm={6}>
                                            <p className="m-0">This month</p>
                                            <h3 className="fw-bold">${CDmonth.v1} 
                                                {(CDmonth.c1>0) ?
                                                    <small className={CDmonth.c2}>
                                                        {(CDmonth.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDmonth.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h3>
                                        </Col>
                                        <Col sm={6} className="text-right text-muted">
                                            <p className="m-0">Last month</p>
                                            <h3>${CDmonth.v2}</h3>
                                        </Col>
                                    </Row>
                                </Container>
                                <Month start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop} onCallback={dataMonth} ></Month>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                                <Container className="mb-15">
                                    <Row>
                                        <Col sm={6}>
                                            <p className="m-0">This year</p>
                                            <h3 className="fw-bold">${CDyear.v1} 
                                                {(CDyear.c1>0) ?
                                                    <small className={CDyear.c2}>
                                                        {(CDyear.c3==='faArrowUp') ? 
                                                        <FontAwesomeIcon icon={faArrowUp} className="menuicon ml-5" />  : 
                                                        <FontAwesomeIcon icon={faArrowDown} className="menuicon ml-5" />} 
                                                        {CDyear.c1}%
                                                    </small>
                                                    : ""
                                                }
                                            </h3>
                                        </Col>
                                        <Col sm={6} className="text-right text-muted">
                                            <p className="m-0">Last year</p>
                                            <h3>${CDyear.v2}</h3>
                                        </Col>
                                    </Row>
                                </Container>
                                <Year start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop} onCallback={dataYear} ></Year>
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>

            <Container fluid className="mt-40">
                <Col sm={12}>
                    <h4 className="page-heading">Average order value over time</h4>
                </Col>
                <Col sm={12}>
                    <Card body>
                        <AvgOrderValue start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop}></AvgOrderValue>
                    </Card>
                </Col>
            </Container>

            <Container fluid className="mt-40">
                <Col sm={12}>
                    <h4 className="page-heading">Conversion Funnel</h4>
                </Col>
                <Col sm={12}>
                    <Card body>
                        <Funnel start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop}></Funnel>
                    </Card>
                </Col>
            </Container>


            <Container fluid className="mt-40">
                <Col sm={12}>
                    <h4 className="page-heading">Conversations replied over time</h4>
                </Col>
                <Col sm={12}>
                    <Card body>
                        <Conversations start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop}></Conversations>
                    </Card>
                </Col>
            </Container>


            <Container fluid className="mt-40">
                <Col sm={12}>
                    <h4 className="page-heading">Quantity of purchases</h4>
                </Col>
                <Col sm={12}>
                    <Card body>
                        <Quantityp start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop}></Quantityp>
                    </Card>
                </Col>
            </Container>


            <Container fluid className="mt-40">
                <Col sm={12}>
                    <h4 className="page-heading">Recovery rate</h4>
                </Col>
                <Col sm={12}>
                    <Card body>
                        <Recovery start={state.start.format('YYYY-MM-DD')} end={state.end.format('YYYY-MM-DD')} shop={shop}></Recovery>
                    </Card>
                </Col>
            </Container>
        </>
    );
}

export default Insight;